import React, { Fragment, useState } from "react"
import RichText from "../RichText"
import {
  Form,
  Container,
  RadioButtons,
  YarnSubscribe,
  LegalCheckboxContainer
} from "./styles"

const TextInput = ({ showLabel, labelText, children }) => {
  return (
    <>
      {showLabel && <label>{labelText}</label>}
      {children}
    </>
  )
}

const Component = ({ form, stageID }) => {
  const [success, setSuccess] = useState(null)
  const [subscribeTicked, setSubscribeTicked] = useState(false)
  const [checkboxRequired, setCheckboxRequired] = useState(false)
  const {
    data: {
      body: sliceZone,
      campaign_monitor_list_id: listID,
      submit_button_label: submitLabel,
      success_message: successMessage,
      include_yarn_subscribe_checkbox: withYarnSubscribe,
      legal_checkbox_label: LegalCheckboxLabel,
      consent_text: consentText,
      show_form_labels
    }
  } = form
  // agree to T&C checkbox start
  const toggleCheckbox = e => {
    setSubscribeTicked(!subscribeTicked)
  }
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState("")

  const handleLegalCheckboxChange = event => {
    setIsChecked(event.target.checked)
    if (error) {
      setError("") // Clear error if user checks the box after an error message
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (true && !isChecked) {
      setError("You must agree to the Terms of use before submitting.")
    } else {
      const formData = new FormData(event.target)
      let object = { CustomFields: [] }
      formData.forEach((value, key) => {
        if (
          ["name", "email", "downloadListID", "stageID", "source"].includes(key)
        ) {
          object[key] = value
        } else {
          object["CustomFields"].push({
            Key: key,
            Value: value
          })
        }
      })
      if (object["source"] === "https://www.yarno.com.au/free-trial") {
        object["message"] = `Company name: ${formData.get(
          "company"
        )}. Campaign: ${formData.get("campaign_type")}`
        object["phone"] = formData.get("mobile")
      }
      const body = JSON.stringify(object)
      const response = await fetch("/api/contact-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: body
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          setSuccess(true)
        })

      if (subscribeTicked && withYarnSubscribe) {
        handleYarnSubscribe(name, email)
      }
    }
  }

  const handleYarnSubscribe = (name, email) => {
    const response = fetch("/api/contact-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: name,
        email: email,
        downloadListID: "2c598a44455e88cb3c08ef8db31df9cf" // yarn list ID
      })
    }).then(function (response) {
      return response.json()
    })
  }

  return (
    <Container>
      {success && successMessage && (
        <RichText render={successMessage.richText} />
      )}
      {!success && (
        <Form onSubmit={handleSubmit}>
          {/* Name and Email are required fields for Campaign Monitor, so they are hardcoded here, */}
          {/* therefore don't add those fields to the prismic slice, or you'll get duplication  */}
          <div>
            <input
              type="hidden"
              name="downloadListID"
              id="downloadListID"
              value={listID}
            />
            {stageID && (
              <Fragment>
                <input
                  type="hidden"
                  name="stageID"
                  id="stageID"
                  value={stageID}
                />
                {listID == "94a147eb62ad7e1429f2bc1b191b97fc" && (
                  <Fragment>
                    <input
                      type="hidden"
                      name="source"
                      id="source"
                      value={"https://www.yarno.com.au/free-trial"}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
            <TextInput showLabel={show_form_labels} labelText="Name*">
              <input
                type="text"
                name="name"
                id="name"
                placeholder={show_form_labels ? "" : "Name"}
                required
              />
            </TextInput>

            <TextInput showLabel={show_form_labels} labelText="Email*">
              <input
                type="email"
                name="email"
                id="email"
                placeholder={show_form_labels ? "" : "Email address"}
                required
              />
            </TextInput>
          </div>
          {sliceZone &&
            sliceZone.map((zone, index) => {
              switch (zone.slice_type) {
                case "text_input":
                  return (
                    <TextInput
                      key={`form-input-${index}`}
                      showLabel={show_form_labels}
                      labelText={zone.primary.text_input_label}>
                      <input
                        type="text"
                        name={zone.primary.text_input_name_attribute}
                        id={zone.primary.text_input_name_attribute}
                        required={zone.primary.text_input_required}
                        placeholder={
                          show_form_labels ? "" : zone.primary.text_input_label
                        }
                      />
                    </TextInput>
                  )
                case "radio_input":
                  return (
                    <RadioButtons key={`form-input-${index}`}>
                      {show_form_labels && (
                        <label>{zone.primary.radio_input_label}</label>
                      )}
                      {zone.items.map((item, i) => (
                        <div key={`form-input-radio-${i}`}>
                          <input
                            key={`form-input-radio-${i}`}
                            type="radio"
                            name={zone.primary.radio_input_name_attribute}
                            id={item.radio_input_option_label}
                            value={item.radio_input_option_label}
                            required={zone.primary.radio_input_required}
                          />
                          <label htmlFor={item.radio_input_option_label}>
                            {item.radio_input_option_label}
                          </label>
                        </div>
                      ))}
                    </RadioButtons>
                  )
              }
            })}
          {withYarnSubscribe && (
            <YarnSubscribe>
              <div>
                <input
                  name="yarn-subscribe"
                  id="yarn-subscribe"
                  type="checkbox"
                  checked={subscribeTicked}
                  onChange={toggleCheckbox}
                />
              </div>
              <label htmlFor="yarn-subscribe">
                Also, sign up for our monthly e-newsletter, The Yarn, to receive
                the latest in learning trends, remote work, customer service and
                other cool stuff!
              </label>
            </YarnSubscribe>
          )}
          {LegalCheckboxLabel && (
            <LegalCheckboxContainer>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleLegalCheckboxChange}
              />
              <label>
                <RichText render={LegalCheckboxLabel.richText} />
              </label>
            </LegalCheckboxContainer>
          )}
          {error && <small>{error}</small>}
          <button type="submit">{submitLabel}</button>
          <p>
            <small>{consentText}</small>
          </p>
        </Form>
      )}
    </Container>
  )
}

export default Component
